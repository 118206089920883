import * as React from 'react';
import { useState } from "react";
import emailjs from "emailjs-com";
import {_get, _getloader, _post} from "../Config/axiosInstance"
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { Typography } from '@mui/material';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const initialState = {
  name: "",
  email: "",
  message: "",
};
export const Contact = (props) => {
  const [open, setOpen] = useState(false);
  const [fadeOut, setFadeOut] = useState(false);
  const [{ name, email, message }, setState] = useState(initialState);
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };
  const clearState = () => setState({ ...initialState });
  
  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  const handleClickOpen = () => {
    setOpen(true);
    setFadeOut(false);
    setTimeout(() => {
      setFadeOut(true);
      setTimeout(() => {
        setOpen(false);
      }, 500); // Match the transition duration
    }, 3000); // 5 seconds delay
  };


  const handleClose = () => {
    setOpen(false);
  };
  
  const handleSubmit = async(e) => {
    e.preventDefault();
    console.log(name, email, message);

    const makePayload = {
      name:name,
      email:email,
      message:message
    }
console.log(makePayload)
    try {
      setLoading(true);
      const response = await _post('saveContactUs',makePayload);
     console.log(response)
     handleClickOpen()
    } catch (error) {
      console.error("Error fetching data:", error);
    }finally{
      setLoading(false);
    }
    
    {/* replace below with your own Service ID, Template ID and Public Key from your EmailJS account */ }
    
    // emailjs
    //   .sendForm("YOUR_SERVICE_ID", "YOUR_TEMPLATE_ID", e.target, "YOUR_PUBLIC_KEY")
    //   .then(
    //     (result) => {
    //       console.log(result.text);
    //       clearState();
    //     },
    //     (error) => {
    //       console.log(error.text);
    //     }
    //   );
  };

  if(loading){
    return _getloader();
  }
  return (
    <>
    <div>
      <div id="contact">
        <div className="container">
          <div className="col-md-8">
            <div className="row">
              <div className="section-title">
                <h2>Get In Touch</h2>
                <p style={{ color: 'black' }}>
                  Please fill out the form below to send us an email and we will
                  get back to you as soon as possible.
                </p>
              </div>
              <form name="sentMessage" validate="true" onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        id="name"
                        name="name"
                        className="form-control"
                        placeholder="Name"
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="email"
                        id="email"
                        name="email"
                        className="form-control"
                        placeholder="Email"
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <textarea
                    name="message"
                    id="message"
                    className="form-control"
                    rows="4"
                    placeholder="Message"
                    required
                    onChange={handleChange}
                  ></textarea>
                  <p className="help-block text-danger"></p>
                </div>
                <div id="success"></div>
                <button type="submit" className="btn btn-custom btn-lg">
                  Send Message
                </button>
              </form>
            </div>
          </div>
          <div className="col-md-3 col-md-offset-1 contact-info">
            <div className="contact-item">
              <h3 style={{ color: 'black' }}>Contact Info</h3>
              <p style={{ color: 'black' }}>
                <span >
                  <i className="fa fa-map-marker"></i> Address
                </span>
                {props.data ? props.data.address : "loading"}
              </p>
            </div>
            <div className="contact-item">
              <p style={{ color: 'black' }}>
                <span>
                  <i className="fa fa-phone"></i> Phone
                </span>{" "}
                {props.data ? props.data.phone : "loading"}
              </p>
            </div>
            <div className="contact-item">
              <p style={{ color: 'black' }}>
                <span>
                  <i className="fa fa-envelope-o"></i> Email
                </span>{" "}
                {props.data ? props.data.email : "loading"}
              </p>
            </div>
          </div>
          <div className="col-md-12">
            <div className="row">
              <div className="social">
                <ul>
                  <li>
                    <a href={props.data ? props.data.facebook : "/"}>
                      <i className="fa fa-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.twitter : "https://x.com/Luknosipl"}>
                      <i className="fa fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.youtube : "/"}>
                      <i className="fa fa-youtube"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="footer">
        <div className="container text-center">
          <p>
            &copy; 2024 Luknos Ind Pvt Ltd.            
          </p>
        </div>
      </div>
    </div>
    {open && (
        <div className={`fade-transition ${fadeOut ? 'fade-out' : 'fade-in'}`}>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className='Dialogtitle'>Thank You!</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={(theme) => ({
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <DialogContentText className='Dialogcontent' id="alert-dialog-slide-description">
          {/* <Typography variant='h2'>Thank You!</Typography> */}

          <Typography>Thank you for reaching out to us. We’ve received your message and will get back to you as soon as possible.</Typography><br/>

          <Typography>If you have any urgent questions or need immediate assistance, please feel free to contact us directly.</Typography> <br/>


<Typography>Best regards,</Typography>
<Typography>Luknos Ind Pvt Ltd (Sales Team) - <br/>+91 9890 321 321</Typography>
          </DialogContentText>
        </DialogContent>
       
      </Dialog>
    
    </div>
    )}
    </>
  );
};
